import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "columns is-centered" }
const _hoisted_2 = { class: "column is-5" }
const _hoisted_3 = { class: "box mt-6" }
const _hoisted_4 = {
  key: 0,
  class: "notification is-danger"
}
const _hoisted_5 = { class: "field" }
const _hoisted_6 = { class: "label" }
const _hoisted_7 = { class: "control" }
const _hoisted_8 = { class: "field" }
const _hoisted_9 = { class: "label" }
const _hoisted_10 = { class: "control" }
const _hoisted_11 = { class: "button is-fullwidth is-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.loginFailed)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('auth.LOGIN_FAILED')), 1))
            : _createCommentVNode("", true),
          _createElementVNode("form", {
            action: "",
            method: "post",
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('user.EMAIL')), 1),
              _createElementVNode("div", _hoisted_7, [
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  class: "input",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.email) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.form.email]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('user.PASSWORD')), 1),
              _createElementVNode("div", _hoisted_10, [
                _withDirectives(_createElementVNode("input", {
                  type: "password",
                  class: "input",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.form.password]
                ])
              ])
            ]),
            _createElementVNode("button", _hoisted_11, _toDisplayString(_ctx.$t('auth.LOGIN')), 1)
          ], 32)
        ])
      ])
    ])
  ]))
}