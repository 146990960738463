
import { defineComponent, ref, reactive } from 'vue';
import AuthService from '@/services/AuthService';
import { useRouter } from 'vue-router';

export default defineComponent({
    setup() {
        const router = useRouter();
        const loginFailed = ref(false);
        const form = reactive({
            email: '',
            password: '',
        });

        function login() {
            AuthService.login(form).then(() => {
                router.push({
                    name: 'catalog-index',
                });
            }).catch(() => {
                loginFailed.value = true;
            });
        }

        return {
            form,
            login,
            loginFailed
        };
    },
});
